import { providers, Contract, getDefaultProvider, ethers } from "ethers";
import { contractAddr, network, zeroAddress } from '../../environment';
import abi from './abi/nepta-nft.json';
import { MetaMask, CurrencyConvert } from 'nft-web3';
import api from '../api/api';

const currencyConvert = new CurrencyConvert();

export const provider = new providers.FallbackProvider(
    [getDefaultProvider(network)],
    1
);
export const contract = new Contract(
    contractAddr,
    abi,
    provider
);

export const loadNftData = async () => {
    let address = zeroAddress;
    if (sessionStorage.getItem('account')) {
        address = sessionStorage.getItem('account');
    }
    const configs = await api.configuration();
    const data = {};
    for (let config of configs.data.data) {
        data[config.key] = config.value;
        if (config.key === 'mintEnabled' || config.key === 'mintDiscountEnabled') {
            data[config.key] = config.value === 'true' ? true : false;
        }
        if (config.key === 'mintRate') {
            data.basePrice = Number(config.value);
        }
        if (config.key === 'mintDiscount') {
            data[config.key] = Number(config.value);
        }
    }
    const discountedPrice = ((data.mintDiscount/100) * data.basePrice);
    const price = Number(
        ethers.utils.formatUnits(
            await contract.getPrice(address), 
            18
        )
    );
    data.isWhiteListed = false;
    if (price === discountedPrice) {
        data.isWhiteListed = true;
    } 
    if (data.mintDiscountEnabled && data.isWhiteListed) {
        data.mintRate = discountedPrice;
    }
    return data;      
}

export const nftTotalSupply = async () => {
    const totalSupply = await contract.totalSupply();
    return totalSupply.toString();
}

export const nftMaxMints = async () => {
    const maxMints = await contract.maxMints();
    return maxMints;
}

export const mintNft = async (quantity, walletObj) => {
    const mintRate = await contract.getPrice(walletObj.selectedAddress);
    const wallet = new MetaMask(walletObj);
    const account = await wallet.connectMetaMask();
    const mintValue = (quantity * mintRate.toString()).toString();
    if (account) {
        const mintTx = await wallet.sendTransaction(
            contractAddr,
            abi,
            "mint",
            [quantity],
            {
              gasLimit: currencyConvert.toWei("1000000", "wei"),
              value: currencyConvert.toWei(mintValue, "wei"),
            }
        );
        return mintTx;
    } 
}