import { MetaMask } from "nft-web3";
import { chainIds, network } from '../../environment';
import { addWalletSession, clearWalletSession, getWalletSession } from "./session";
import { wallets } from './wallets';
import { Errors } from './errors';
import MetaMaskOnboarding from '@metamask/onboarding';

const onboarding = new MetaMaskOnboarding();
const WALLET = wallets.MetaMask;

export const connectMetaMask = async () => {
    const ethereum = window.ethereum;
    try {
      if (MetaMaskOnboarding.isMetaMaskInstalled()) {
        const metaMask = new MetaMask(ethereum);
        const account = await metaMask.connectMetaMask();
        if (account) {
            //onboarding.stopOnboarding();
            addWalletSession(WALLET, account);
            await detectAccountChanged();
            await handleNetworkChanged();
        }
        return account;
      } else {
        sessionStorage.removeItem('METAMASK_NOT_CONNECTING');
        onboarding.startOnboarding();
        //throw new Error(Errors.METAMASK_NOT_INSTALLED);
      }
    } catch (err) {
      throw new Error(err.message);
    }
}

export const checkMetaConnected = async () => {
    try {
        const session = getWalletSession();
        if (session.wallet === WALLET) {
            if (MetaMaskOnboarding.isMetaMaskInstalled()) {
                const ethereum = window.ethereum;
                await handleNetworkChanged();
                const accounts = await ethereum.request({
                    method: "eth_requestAccounts",
                    params: [],
                });
                await handleAccountsChanged(accounts); 
                return accounts[0];
            } else {
                clearWalletSession();
                console.log(Errors.METAMASK_NOT_INSTALLED);
                throw new Error(Errors.METAMASK_NOT_INSTALLED); 
            }
        }
        return;
    } catch(err) {
        clearWalletSession();
        throw new Error(err.message);
    }
}

export const detectAccountChanged = () => {
    const ethereum = window.ethereum;
    if (ethereum && ethereum.isMetaMask) {
        window.ethereum.on('accountsChanged', handleAccountsChanged);
    } else {
        throw new Error(Errors.METAMASK_NOT_INSTALLED);
    }
}

export const handleAccountsChanged = (accounts) => {
    if (accounts.length === 0) {
        clearWalletSession();
        throw new Error(Errors.METAMASK_NOT_CONNECTED);
    }
    if (
        accounts[0] !== sessionStorage.getItem('account') && 
        !sessionStorage.getItem('ACCOUNT_CHANGED')
    ) {
        sessionStorage.setItem('ACCOUNT_CHANGED', true);
    }
    addWalletSession(WALLET, accounts[0]);
}

export const detectNetworkChanged = () => {
    const ethereum = window.ethereum;
    if (ethereum && ethereum.isMetaMask) {
        window.ethereum.on('chainChanged', handleNetworkChanged);
    }
}

export const handleNetworkChanged = (id) => {
    const chainId = (id || window.ethereum.chainId).replace('0x', '');
    if (chainId !== chainIds[network]) {
        throw new Error(`Please select ${network} network`)
    }
}