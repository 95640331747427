export const addWalletSession = (wallet, account) => {
    sessionStorage.setItem("wallet", wallet);
    sessionStorage.setItem("account", account);
}

export const clearWalletSession = () => {
    sessionStorage.removeItem("wallet");
    sessionStorage.removeItem("account");
    sessionStorage.removeItem("NFT_MINTED");
    sessionStorage.removeItem('ACCOUNT_CHANGED');
}

export const getWalletSession = () => {
    return {
        wallet: sessionStorage.getItem("wallet"),
        account: sessionStorage.getItem("account")
    }
}