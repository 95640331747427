import { useContext } from "react";
import CloseImage from "../../assets/images/close.png";
import { Errors } from "../../utils/wallets/errors";
import { NftsContext } from "../Intro";
import Error from "../../assets/images/error.png";

function ErrorPopUp() {
  const closePopup = () => {
    setIsErrorPopupOpened(false);
    if (errorMessage === Errors.METAMASK_NOT_CONNECTING) {
      window.location.reload(false);
    }
  };
  const { errorMessage, setIsErrorPopupOpened } = useContext(NftsContext);
  return (
    <div className="popup__overlay">
      <div className="popup__content h-600">
        <div className="popup__image">
          <img src={Error} alt="progress" draggable="false" />
        </div>
        <div className="popup__close" onClick={closePopup}>
          <img src={CloseImage} />
        </div>
        <div className="popup__message">{errorMessage}</div>
        {
          errorMessage === Errors.MINTED_FAILED ? (
            <div className="popup__info">
              Sorry. Minting has failed. Please try again.
            </div>
          ): null
        }
      </div>
    </div>
  );
}

export default ErrorPopUp;
