import { useEffect, useState } from "react";
import "./assets/scss/app.scss";
import Intro, { MobileIntro, NftsContextProvider } from "./components/Intro";
import Navbar from "./components/Navbar";

function TopAlert() {
  const [isWhiteListed, setWhiteListed] = useState(false);
  useEffect(() => {
    setInterval(() => {
      if (
        sessionStorage.getItem("account") &&
        sessionStorage.getItem("WHITELIST_ADDRESS") === "true"
      ) {
        setWhiteListed(true);
      } else {
        setWhiteListed(false);
      }
    }, 600);
  });
  return (
    <div>
      {isWhiteListed ? (
        <div className="top-alert">
          Congratulations! Your wallet is part of the whitelist addresses.
        </div>
      ) : null}
    </div>
  );
}
function App() {
  return (
    <div className="App">
      <NftsContextProvider>
        <Navbar />
        <TopAlert />
        <MobileIntro />
        <div className="container">
          <Intro />
        </div>
      </NftsContextProvider>
    </div>
  );
}

export default App;
