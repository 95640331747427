import { useContext } from "react";
import CloseImage from "../../assets/images/close.png";
import { NftsContext } from "../Intro";
import SuccessIcon from "../../assets/images/success.png";

function SuccessPopUp() {
  const { successMessage, setIsSuccessPopupOpened } = useContext(NftsContext);

  return (
    <div className="popup__overlay">
      <div className="popup__content h-600">
        <div
          className="popup__close"
          onClick={() => {
            setIsSuccessPopupOpened(false);
          }}
        >
          <img src={CloseImage} />
        </div>

        <div className="popup__image">
          <img src={SuccessIcon} alt="progress" draggable="false" />
        </div>
        <div className="popup__message">
          <p style={{ color: "#d1a3e0" }}>{successMessage}</p>
        </div>
        <p className="popup__info">you’ll be directed to your nft reveal...</p>
      </div>
    </div>
  );
}

export default SuccessPopUp;
