import axios from './axios';

const getAddressNfts = async (walletAddress) => {
    const nfts = await axios({
        method:'get',
        url:'nfts/get-address-nfts',
        params: { walletAddress },
    });
    return nfts;
}

const configuration = async () => {
    const configs = await axios({
        method:'get',
        url:'configuration'
    });
    return configs;
}

const mintNfts = async (startTokenId, walletAddress) => {
    const nfts = await axios({
        method:'post',
        url:'nfts/mint-nfts',
        data: { startTokenId, walletAddress },
    });
    return nfts;
}

const addWalletAddress = async (walletAddress) => {
    const nfts = await axios({
        method:'post',
        url:'wallet-address/add-wallet-address',
        data: { 
            walletAddress
        },
    });
    return nfts;
}

const api = {
    getAddressNfts,
    addWalletAddress,
    configuration,
    mintNfts
}

export default api;
