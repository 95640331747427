import { useContext } from "react";
import { useEffect } from "react";
import { Errors } from "../../utils/wallets/errors";
import { NftsContext } from "../Intro";
import { getWalletSession } from "../../utils/wallets/session";
import { wallets } from "../../utils/wallets/wallets";
import Progress from "../../assets/images/progress.png";

const { wallet } = getWalletSession();

function MintingNftPopup() {
  const { setIsMintingPopUpOpened, setIsErrorPopupOpened, setErrorMessage } =
    useContext(NftsContext);
  const checkMetaConnected = () => {
    if (wallet === wallets.MetaMask) {
      sessionStorage.setItem("METAMASK_NOT_CONNECTING", true);
    }
    setTimeout(async () => {
      if (sessionStorage.getItem("METAMASK_NOT_CONNECTING")) {
        // setIsMintingPopUpOpened(false);
        // setErrorMessage(Errors.METAMASK_NOT_CONNECTING);
        // setIsErrorPopupOpened(true);
        sessionStorage.removeItem("METAMASK_NOT_CONNECTING");
      }
    }, 120 * 1000);
  };
  useEffect(() => {
    checkMetaConnected();
  }, []);
  return (
    <div className="popup__overlay">
      <div className="popup__content" style={{ padding: "170px 33px" }}>
        <div className="popup__image">
          <img src={Progress} alt="progress" draggable="false" />
        </div>
        <div className="popup__message" style={{ width: "100%", margin: "0" }}>
          MINTING IN <br /> PROGRESS...
        </div>
        <p className="popup__info">
          In a few moments, your NFT will be minted...
        </p>
      </div>
    </div>
  );
}

export default MintingNftPopup;
