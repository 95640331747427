import { useContext } from "react";
import CloseImage from "../../assets/images/close.png";
import MetaMask from "../../assets/images/metamask.png";
import WalletConnect from "../../assets/images/walletconnect.png";
import { NftsContext } from "../Intro";
import { connectMetaMask, handleNetworkChanged } from '../../utils/wallets/metamask';
import { nftMaxMints } from "../../utils/contracts/nft-nepta";
import api from "../../utils/api/api";
import { wallets } from "../../utils/wallets/wallets";
import { getWalletSession } from "../../utils/wallets/session";

function ConnectPopUp() {
  const { 
    setNftsCount,
    setIsPopUpOpened, 
    setIsConnectPopUpOpened, 
    setErrorMessage, 
    setIsErrorPopupOpened,
    setIsLoggedIn,
    setMaxMints,
  } = useContext(NftsContext);
  
  const handleMetaConnect = async () => {
    sessionStorage.setItem('METAMASK_NOT_CONNECTING', true);
    setIsPopUpOpened(false);
    setIsConnectPopUpOpened(true);
    try {
      const account = await connectMetaMask();
      sessionStorage.removeItem('METAMASK_NOT_CONNECTING');
      if (window.ethereum) {
        window.ethereum.on('accountsChanged', (accounts) => setNftsCount(0));
        window.ethereum.on('chainChanged', (id) => {
          const session = getWalletSession();
          if (session.wallet === wallets.MetaMask) {
            try {
              handleNetworkChanged(id);
            } catch (err) {
              setIsConnectPopUpOpened(false);
              setErrorMessage(err.message);
              setIsErrorPopupOpened(true);
            }
          }
        })
        const maxMints = await nftMaxMints();
        setMaxMints(maxMints);
        api.addWalletAddress(account);
        setIsLoggedIn(true);
      }
      setIsConnectPopUpOpened(false);
      setErrorMessage('');
      setIsErrorPopupOpened(false);
    } catch(err) {
      sessionStorage.removeItem('METAMASK_NOT_CONNECTING');
      setIsConnectPopUpOpened(false);
      if (err.message.includes('wallet_requestPermissions')) {
        setErrorMessage(`Please check the metamask wallet request already in pending`);
        setIsErrorPopupOpened(true);
      } else if (err.message !== 'User rejected the request.') {
        setErrorMessage(err.message || 'Something went wrong');
        setIsErrorPopupOpened(true);
      } else {
        setIsErrorPopupOpened(false);
      }
      console.log(err.message);
    }
  };

  const handleWalletConnect = () => {};

  return (
    <div className="popup__overlay">
      <div className="popup__content ">
        <div
          className="popup__close"
          onClick={() => {
            setIsPopUpOpened(false);
          }}
        >
          <img src={CloseImage} />
        </div>
        <div className="popup__message">Connect A Wallet</div>
        <div className="popup__buttons">
          <button onClick={handleMetaConnect}>
            <img src={MetaMask} draggable="false" alt="metamask" /> MetaMask
          </button>
          {/* <button onClick={handleWalletConnect} className="wallet-btn">
            <div>
              <img src={WalletConnect} alt="walletconnect" draggable="false" />
            </div>
            WALLET Connect
          </button> */}
        </div>
      </div>
    </div>
  );
}

export default ConnectPopUp;
