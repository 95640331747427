import { useContext, useEffect, useRef, useState } from "react";
import Logo from "../assets/images/logo.png";
import gsap from "gsap";
import { NftsContext } from "./Intro";
import { checkMetaConnected } from '../utils/wallets/metamask';
import NavbarAddress from "./NavbarAddress";
import api from "../utils/api/api";

function Navbar() {
  const navbarRef = useRef();
  const { 
    isLoggedIn, 
    setAccount, 
    setIsLoggedIn, 
    setIsPopUpOpened,
    setIsProcessingPopUpOpened,
    setIsConnectPopUpOpened,
    setIsSuccessPopupOpened
  } = useContext(NftsContext);
  const [whitepaper, setWhitepaper] = useState("");
  const [learnMore, setLearnMore] = useState("");
  useEffect(() => {
    api.configuration()
    .then(configs => {
      localStorage.setItem('configs', configs);
      for (const config of configs.data.data) {
        if (config.key === 'whitepaper') {
          setWhitepaper(config.value);
        }
        if (config.key === 'learnMore') {
          setLearnMore(config.value);
        }
      };
    });
    if (navbarRef.current) {
      gsap.fromTo(
        navbarRef.current,
        {
          yPercent: -200,
          opacity: 0,
          delay: 0.1,
          duration: 1.3,
          ease: "expo.out",
        },
        {
          yPercent: 0,
          opacity: 1,
          delay: 0.1,
          duration: 1.3,
          ease: "expo.out",
        }
      );
    }
    setInterval(async () => {
      try {
        // check metamask connect than login by default
        const account = await checkMetaConnected();
        if (account) {
          // wallet login
          setAccount(account);
          setIsLoggedIn(true);
        }
      } catch(err) {
        // logout if error thrown
        setIsSuccessPopupOpened(false);
        setIsProcessingPopUpOpened(false);
        setIsConnectPopUpOpened(false);
        setAccount('');
        setIsLoggedIn(false);
      }
    }, 2000);
  }, []);
  const [state, setState] = useState(false);
  const handleClick = () => {
    setState(!state);
  };
  return (
    <nav className="navbar" ref={navbarRef}>
      <div className="container">
        <div className="navbar__content">
          <div className="navbar__left">
            <svg
              className={`ham hamRotate ham8 ${state ? "active" : ""}`}
              viewBox="0 0 100 100"
              width="50"
              onClick={handleClick}
            >
              <path
                className="line top"
                d="m 30,33 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20"
              />
              <path className="line middle" d="m 30,50 h 40" />
              <path
                className="line bottom"
                d="m 70,67 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20"
              />
            </svg>
            <div className="navbar__logo">
              <img src={Logo} alt="logo" draggable="false" />
            </div>
          </div>
          <div className="navbar__right">
            <ul className="navbar__list">
              <li className="navbar__item">
                <a href={learnMore} target="_blank">
                  learn more
                </a>
              </li>
              <li className="navbar__item">
                <a
                  href={whitepaper}
                  target="_blank"
                >
                  read our whitepaper
                </a>
              </li>
              {
                !isLoggedIn ? (
                  <li
                    className="navbar__item"
                    onClick={(e) => {
                      e.preventDefault();
                      setIsPopUpOpened(true);
                      sessionStorage.removeItem('METAMASK_NOT_CONNECTING');
                    }}
                  >
                    <a href="#" target="_blank">
                      connect wallet
                    </a>
                  </li>
                ) : (
                  <NavbarAddress />
                )}

            </ul>
          </div>
        </div>
      </div>
      {state ? (
        <div className="mobile__menu">
          <ul className="mobile__list">
            <li className="mobile__item">
              <a href="https://www.metanept.io/" target="_blank">
                learn more
              </a>
            </li>
            <li className="mobile__item">
              <a
                href="https://www.metanept.io/documents/nept_whitepaper.pdf"
                target="_blank"
              >
                read our whitepaper
              </a>
            </li>
          </ul>
        </div>
      ) : null}
    </nav>
  );
}

export default Navbar;
