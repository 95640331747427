import { useContext } from "react";
import { useEffect } from "react";
import { Errors } from "../../utils/wallets/errors";
import { NftsContext } from "../Intro";

function ConnectingWallet() {
  const {
    setErrorMessage,
    setIsErrorPopupOpened,
    setIsConnectPopUpOpened
  } = useContext(NftsContext);
  const checkMetaConnected = () => {
    setTimeout(async () => {
      if (sessionStorage.getItem('METAMASK_NOT_CONNECTING')) {
        setErrorMessage(Errors.METAMASK_NOT_CONNECTING);
        setIsErrorPopupOpened(true);
      }
      setIsConnectPopUpOpened(false);
    }, 60 * 1000);
  }
  useEffect(() => {
    checkMetaConnected();
  }, [])
  return (
    <div className="popup__overlay">
      <div className="popup__content" style={{ padding: "170px 33px" }}>
        <div className="popup__message" style={{ width: "100%", margin: "0" }}>
          Conneting a wallet...
        </div>
      </div>
    </div>
  );
}

export default ConnectingWallet;
