import Progress from "../../assets/images/progress.png";

function ProcessingPopup() {
    return (
      <div className="popup__overlay">
      <div className="popup__content" style={{ padding: "170px 33px" }}>
        <div className="popup__image">
          <img src={Progress} alt="progress" draggable="false" />
        </div>
        <div className="popup__message" style={{ width: "100%", margin: "0" }}>
          MINTING IN <br /> PROGRESS...
        </div>
        <p className="popup__info">
          In a few moments, your NFT will be minted...
        </p>
      </div>
    </div>
    );
}
  
export default ProcessingPopup;
