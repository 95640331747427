import Lock from "../assets/images/Vector.png";
import NFT from "../assets/images/nft.png";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import gsap, { Power4 } from "gsap";
import ConnectPopUp from "./popups/ConnectPopUp";
import { nftTotalSupply, loadNftData } from "../utils/contracts/nft-nepta";
import ConnectingWallet from "./popups/ConnectingWallet";
import { mintNft, provider } from "../utils/contracts/nft-nepta";
import { getWalletSession } from "../utils/wallets/session";
import { wallets } from "../utils/wallets/wallets";
import MintingNftPopup from "./popups/MintingNftPopup";
import ProcessingPopup from "./popups/ProcessingPopup";
import ErrorPopUp from "./popups/ErrorPopup";
import SuccessPopUp from "./popups/SuccessPopup";
import { MintingSection } from "./MintingSection";
import Line from "../assets/images/line.png";
import { Errors } from "../utils/wallets/errors";
import api from "../utils/api/api";

export const NftsContext = createContext({
  nfts: 0,
  isLoggedIn: false,
  isPopUpOpened: false,
  isMintingOpened: false,
  isProcessingPopUpOpened: false,
  isErrorPopupOpened: false,
  isSuccessPopupOpened: false,
  errorMessage: "",
  successMessage: "",
  mintedNFT: [],
  account: "",
  maxMints: 0,
  setIsErrorPopupOpened: () => {},
  setIsSuccessPopupOpened: () => {},
  setErrorMessage: () => {},
  setSuccessMessage: () => {},
  setIsPopUpOpened: () => {},
  setIsConnectPopUpOpened: () => {},
  setIsMintingPopUpOpened: () => {},
  setIsProcessingPopUpOpened: () => {},
  setIsLoggedIn: () => {},
  setNftsCount: (v) => {},
  setAccount: () => {},
  setMintedNFT: () => [],
  setMaxMints: () => 0,
});

export function NftsContextProvider({ children }) {
  const [nfts, setNftsCount] = useState(0);
  const [isPopUpOpened, setIsPopUpOpened] = useState(false);
  const [isConnectPopUpOpened, setIsConnectPopUpOpened] = useState(false);
  const [isMintingOpened, setIsMintingPopUpOpened] = useState(false);
  const [isProcessingPopUpOpened, setIsProcessingPopUpOpened] = useState(false);
  const [isErrorPopupOpened, setIsErrorPopupOpened] = useState(false);
  const [isSuccessPopupOpened, setIsSuccessPopupOpened] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [account, setAccount] = useState("");
  const [maxMints, setMaxMints] = useState("");
  const [mintedNFT, setMintedNFT] = useState([]);
  return (
    <NftsContext.Provider
      value={{
        nfts,
        setNftsCount,
        isPopUpOpened,
        setIsPopUpOpened,
        isConnectPopUpOpened,
        setIsConnectPopUpOpened,
        isMintingOpened,
        setIsMintingPopUpOpened,
        isProcessingPopUpOpened,
        setIsProcessingPopUpOpened,
        isLoggedIn,
        setIsLoggedIn,
        isErrorPopupOpened,
        setIsErrorPopupOpened,
        isSuccessPopupOpened,
        setIsSuccessPopupOpened,
        errorMessage,
        setErrorMessage,
        successMessage,
        setSuccessMessage,
        account,
        setAccount,
        mintedNFT,
        setMintedNFT,
        maxMints,
        setMaxMints,
      }}
    >
      {children}
    </NftsContext.Provider>
  );
}

export function MobileIntro() {
  return (
    <div className="mobile-intro" style={{ backgroundImage: `url(${NFT})` }}>
      <div className="mobile__content">
        <div className="nft__total"></div>
      </div>
    </div>
  );
}

function Nft() {
  const intro = useRef(null);
  const { nfts, setMaxMints } = useContext(NftsContext);
  useEffect(() => {
    if (intro.current) {
      gsap.fromTo(
        intro.current,
        {
          scale: 1.4,
          opacity: 0,
          delay: 0.4,
          duration: 1.3,
          ease: "expo.out",
        },
        {
          scale: 1,
          opacity: 1,
          delay: 0.4,
          duration: 1.3,
          ease: "expo.out",
        }
      );
    }
  }, []);
  return (
    <div className="nft" ref={intro}>
      <div className="nft__image">
        {nfts >= 2 ? (
          <img src={NFT} draggable="false" className="img-1 trans" alt="nft" />
        ) : null}
        {nfts >= 3 ? (
          <img src={NFT} draggable="false" className="img-2 trans" alt="nft" />
        ) : null}
        {nfts >= 4 ? (
          <img src={NFT} draggable="false" className="img-3 trans" alt="nft" />
        ) : null}
        {nfts >= 5 ? (
          <img src={NFT} draggable="false" className="img-4 trans" alt="nft" />
        ) : null}
        <div className="nft__main-image">
          <img src={NFT} draggable="false" alt="nft" />
        </div>
      </div>
      <div className="nft__intro">
        <p className="nft__text">NEPTUNIANS X</p>
        <div className="nft__total">
          {/* <div className="nft__info">
            <div className="nft__minted">
              {totalSupply}/{maxTotalSupply} Minted
            </div>
            <div className="splitter">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                fill="#ffffff"
                viewBox="0 0 16.933 16.933"
              >
                <path
                  d="m 12.271329,281.39001 h -0.0423 -10.2445898 c -1.10065995,-0.0423 -1.10065995,1.62982 0,1.58749 H 12.229029 c 1.07949,0.0423 1.12182,-1.58749 0.0423,-1.58749 z m -10.2869098,6.34996 c -1.05832995,0 -1.05832995,1.58748 0,1.58748 h 5.79962 c 1.0795,0 1.0795,-1.58748 0,-1.58748 z m 0,6.34995 c -1.05832995,0 -1.05832995,1.58749 0,1.58749 H 14.980649 c 1.05832,0 1.05832,-1.58749 0,-1.58749 z"
                  transform="translate(0 -280.067)"
                />
              </svg>
            </div>
            <div className="nft__per">{minRate} ETH PER NFT</div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

function Minting() {
  const [checked, setChecked] = useState(false);
  const [minRate, setMinRate] = useState("");
  const [mintEnabled, setMintEnabled] = useState(undefined);
  const [totalSupply, setTotalSupply] = useState("");
  const [maxTotalSupply, setMaxTotalSupply] = useState("");
  const [maxMints, setMaxMints] = useState("");
  const liveRef = useRef(null);
  const titleRef = useRef(null);
  const headingRef = useRef(null);
  const acceptRef = useRef(null);
  const formRef = useRef(null);
  const buttonRef = useRef(null);
  const {
    nfts,
    isLoggedIn,
    setNftsCount,
    setIsMintingPopUpOpened,
    setIsProcessingPopUpOpened,
    setIsErrorPopupOpened,
    setErrorMessage,
    setIsSuccessPopupOpened,
    setSuccessMessage,
  } = useContext(NftsContext);
  const nftMinted = async () => {
    sessionStorage.removeItem("NFT_MINTED");
    try {
      const mintedCount = sessionStorage.getItem('MINTED_COUNT');
      const maxMints = localStorage.getItem('MAX_MINTS');
      const totalMintCount = Number(mintedCount) + nfts;
      if (totalMintCount > maxMints) {
        sessionStorage.removeItem("METAMASK_NOT_CONNECTING");
        setIsMintingPopUpOpened(false);
        setIsErrorPopupOpened(true);
        if (Number(mintedCount) < maxMints) {
          setErrorMessage(`Max ${maxMints} NFTs are allowed to mint per wallet.`);
        } else {
          setErrorMessage(Errors.MAX_LIMIT_REACHED);
        }
      } else {
        setIsMintingPopUpOpened(true);
        const { wallet, account } = getWalletSession();
        let walletObj;
        if (wallet === wallets.MetaMask) {
          walletObj = window.ethereum;
        }
        const mintTx = await mintNft(nfts, walletObj);
        if (mintTx && mintTx.hash) {
          sessionStorage.removeItem("METAMASK_NOT_CONNECTING");
          setIsMintingPopUpOpened(false);
          setIsProcessingPopUpOpened(true);
          const startTokenId = await nftTotalSupply();
          provider.once(mintTx.hash, async (tx) => {
            setNftsCount(0);
            if (tx.status === 1) {
              await api.mintNfts(startTokenId, account);
              sessionStorage.setItem('NFT_MINTED', true);
              setIsProcessingPopUpOpened(false);
              setIsSuccessPopupOpened(true);
              setSuccessMessage("You have successfully minted your nft");
              console.log(`${nfts} Nft minted successfully!`);
            } else {
              setIsProcessingPopUpOpened(false);
              setIsErrorPopupOpened(true);
              setErrorMessage(Errors.MINTED_FAILED);
              console.log(`Nft minting process failed!`);
            }
          });
        }
      }
    } catch (err) {
      sessionStorage.removeItem("METAMASK_NOT_CONNECTING");
      setIsMintingPopUpOpened(false);
      if (err.code !== 4001) {
        setIsErrorPopupOpened(true);
        setErrorMessage(Errors.MINTED_FAILED);
      }
      console.log(err.message);
    }
  };
  useEffect(() => {
    const loadNftMint = async () => {
      const { mintRate, maxSupply, mintEnabled, maxMints } =
        await loadNftData();
      const totalSupply = await nftTotalSupply();
      setTotalSupply(totalSupply);
      setMaxMints(maxMints);
      setMaxTotalSupply(maxSupply);
      setMintEnabled(mintEnabled);
      setMinRate(mintRate)
    };
    loadNftMint();
    setInterval(() => {
      setMinRate(sessionStorage.getItem('MINT_RATE') || 0);
    }, 600);
    setInterval(async () => {
      const totalSupply = await nftTotalSupply();
      setTotalSupply(totalSupply);
    }, 5000);
    if (titleRef.current) {
      gsap.fromTo(
        titleRef.current,
        {
          yPercent: 100,
          opacity: 0,
          delay: 0.3,
          duration: 1,
          ease: Power4.easeInOut,
        },
        {
          yPercent: 0,
          opacity: 1,
          delay: 0.3,
          duration: 1,
          ease: Power4.easeInOut,
        }
      );
    }
    if (headingRef.current) {
      headingRef.current.childNodes.forEach((node, index) => {
        gsap.fromTo(
          node,
          {
            yPercent: 100,
            opacity: 0,
            delay: 0.3 + index / 10,
            duration: 1,
            ease: Power4.easeInOut,
          },
          {
            yPercent: 0,
            opacity: 1,
            delay: 0.3 + index / 10,
            duration: 1,
            ease: Power4.easeInOut,
          }
        );
      });
    }
    if (liveRef.current) {
      gsap.fromTo(
        liveRef.current,
        {
          yPercent: 50,
          opacity: 0,
          delay: 0.7,
          duration: 1,
          ease: Power4.easeInOut,
        },
        {
          yPercent: 0,
          opacity: 1,
          delay: 0.7,
          duration: 1,
          ease: Power4.easeInOut,
        }
      );
    }
    if (formRef.current) {
      gsap.fromTo(
        formRef.current,
        {
          yPercent: 50,
          opacity: 0,
          delay: 0.8,
          duration: 1,
          ease: Power4.easeInOut,
        },
        {
          yPercent: 0,
          opacity: 1,
          delay: 0.8,
          duration: 1,
          ease: Power4.easeInOut,
        }
      );
    }
    if (acceptRef.current) {
      gsap.fromTo(
        acceptRef.current,
        {
          yPercent: 50,
          opacity: 0,
          delay: 0.9,
          duration: 1,
          ease: Power4.easeInOut,
        },
        {
          yPercent: 0,
          opacity: 1,
          delay: 0.9,
          duration: 1,
          ease: Power4.easeInOut,
        }
      );
    }
    if (buttonRef.current) {
      gsap.fromTo(
        buttonRef.current,
        {
          yPercent: 50,
          opacity: 0,
          delay: 1,
          duration: 1,
          ease: Power4.easeInOut,
        },
        {
          yPercent: 0,
          opacity: 1,
          delay: 1,
          duration: 1,
          ease: Power4.easeInOut,
        }
      );
    }
  }, []);
  return (
    <div className="minting">
      <div className="minting__title" ref={titleRef}>
        <p>MINt your nft</p>
      </div>
      <div className="minting__heading">
        <h5 ref={headingRef}>
          <span>Mint your NEpt</span>
          <span>NFt to join the</span>
          <span>metaverse</span>
        </h5>
      </div>
      <div className="minting__live" ref={liveRef}>
        {mintEnabled !== undefined ? (
          mintEnabled === false ? (
            <div className="minting__disabled-left">
              <span></span>
              <p>Minting is Disabled</p>
            </div>
          ) : (
            <div className="minting__live-left">
              <span></span>
              <p>Minting is Live</p>
            </div>
          )
        ) : (
          <div className="minting__disabled-left"></div>
        )}
        <div className="minting__live-right">
          <p>
            {totalSupply}/{maxTotalSupply}
          </p>
          <span>Minted</span>
        </div>
      </div>
      <div className="minting__form" ref={formRef}>
        <div className="flex-me mt-3">
          <div className="minting__add">
            <div className="minting__plus">
              <span
                onClick={() => {
                  if (nfts > 0) {
                    setNftsCount(nfts - 1);
                  }
                }}
              >
                -
              </span>
            </div>
            <div className="minting__value">{nfts}</div>
            <div className="minting__minus">
              {" "}
              <span
                onClick={() => {
                  if (nfts < maxMints) {
                    setNftsCount(nfts + 1);
                  }
                }}
              >
                +
              </span>
            </div>
          </div>
          <div className="minting__calc">
            <div className="minting__eth">
              <span>{nfts * minRate || minRate}</span> <span>ETH</span>
            </div>
            <div className="minting__lock">
              <img src={Lock} alt="lock" draggable="false" />
            </div>
          </div>
        </div>
        <div className="minting__accept" ref={acceptRef}>
          <div
            className={`minting__checkbox ${checked ? "active" : ""}`}
            onClick={() => {
              setChecked(!checked);
            }}
          ></div>
          <div className="minting__message">
            I accept the <span>NFT Sale Agreement</span>
          </div>
        </div>
        <div className="minting__button" ref={buttonRef}>
          <button
            className={`btn btn-primary ${
              nfts === 0 || !checked || !isLoggedIn || !mintEnabled
                ? "disabled"
                : ""
            }`}
            onClick={() => {
              if (!checked || !isLoggedIn || !mintEnabled || !nfts) return;
              nftMinted();
            }}
          >
            Mint NFT
          </button>
        </div>
      </div>
    </div>
  );
}

function NftMinted() {
  const [maxMints, setMaxMints] = useState("");
  const [minRate, setMinRate] = useState("");
  const [basePrice, setBasePrice] = useState("");
  const [isWhiteListed, setIsWhiteListed] = useState(false);
  const [mintDiscountEnabled, setMintDiscountEnabled] = useState(false);
  useEffect(() => {
    const loadNftMint = async () => {
      const { mintRate, basePrice, isWhiteListed, mintDiscountEnabled, maxMints } = await loadNftData();
      setMinRate(mintRate);
      setIsWhiteListed(isWhiteListed);
      setMintDiscountEnabled(mintDiscountEnabled);
      setBasePrice(String(basePrice));
      setMaxMints(String(maxMints));
      localStorage.setItem('MAX_MINTS', maxMints);
      sessionStorage.setItem('MINT_RATE', mintRate);
      sessionStorage.setItem('WHITELIST_ADDRESS', isWhiteListed);
    };
    loadNftMint();
  });
  return (
    <>
      <p className="max-mint">Max {maxMints} mint per wallet</p>
      <p className="nft-minted">
        <span>Price =</span>
        {
          isWhiteListed && mintDiscountEnabled ? (
            <span>
              {" "}
              <span>
                {" "}
                {basePrice} ETH <img src={Line} />
              </span>{" "}
              {minRate} ETH (+ Gas Fees)
            </span>
          ): (
            <span>
              {" "}
              <span>
                {" "}
                {basePrice} ETH
              </span> (+ Gas Fees)
            </span>
          )
        }
      </p>
    </>
  );
}

function Intro() {
  const {
    nfts,
    isPopUpOpened,
    isConnectPopUpOpened,
    isMintingOpened,
    isProcessingPopUpOpened,
    isErrorPopupOpened,
    isSuccessPopupOpened,
    isLoggedIn,
  } = useContext(NftsContext);
  return (
    <div className="app-wrapper">
      <div className="intro">
        {isPopUpOpened ? <ConnectPopUp /> : null}
        {isConnectPopUpOpened ? <ConnectingWallet /> : null}
        {isMintingOpened ? <MintingNftPopup /> : null}
        {isProcessingPopUpOpened ? <ProcessingPopup /> : null}
        {isErrorPopupOpened ? <ErrorPopUp /> : null}
        {isSuccessPopupOpened ? <SuccessPopUp /> : null}
        <div className="intro__content">
          <div className="intro__left">
            <div className="intro__nfts">
              <Nft />
              <NftMinted />
            </div>
          </div>
          <div className="intro__right">
            <Minting />
          </div>
        </div>
      </div>
      {isLoggedIn ? <MintingSection /> : null}
    </div>
  );
}

export default Intro;
