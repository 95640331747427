import { useContext } from "react";
import Vector from "../assets/images/vec.png";
import { NftsContext } from "./Intro";
import { clearWalletSession } from '../utils/wallets/session';

export default function NavbarAddress() {
  const logout = () => {
    setIsLoggedIn(false);
    setNftsCount(0);
    clearWalletSession();
  }
  const { setIsLoggedIn, setNftsCount, account } = useContext(NftsContext);
  return (
    <div className="navbar__address">
      <div className="navbar__circle"></div>
      <div className="navbar__addrr">{account.substring(0, 10) + '...' }</div>
      <div className="navbar__vector">
        <img src={Vector} draggable="false" onClick={logout} />
      </div>
    </div>
  );
}
