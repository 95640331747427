import { useEffect, useState, useContext } from "react";
import Image from "../assets/images/item.png";
import Avatar from "../assets/images/avatar.png";
import { NftsContext } from "./Intro";
import api from "../utils/api/api";
import { getWalletSession } from "../utils/wallets/session";
import { nftMaxMints } from "../utils/contracts/nft-nepta";

function BlankNftImage({ index }) {
  return <div className="single-nft blank-nft-image">{index}</div>;
}

function MintedNftItem({ heading, image, price }) {
  return (
    <div className="nft-item single-nft">
      <div className="nft-item__image">
        <img
          src={image}
          alt="nft-image"
          className="nft-image"
          draggable="false"
        />
      </div>
      <div className="nft-item__info">
        <h4>{heading}</h4>
      </div>
    </div>
  );
}

function MintingSection() {
  const [mintedCount, setMintedCount] = useState(0);
  const [nft, setNft] = useState([]);
  const [maxMint, setMaxMint] = useState(0);
  useEffect(() => {
    setMaxMint(0);
    setMintedCount(0);
    setInterval(() => {
      const { account } = getWalletSession();
      const mintCount = Number(sessionStorage.getItem("MINTED_COUNT")  || 0);
      if (sessionStorage.getItem("NFT_MINTED")) {
        api.getAddressNfts(account).then((result) => {
          const cnt = result.data.data.length;
          sessionStorage.setItem("MINTED_COUNT", cnt);
          setNft(result.data.data);
          setMintedCount(cnt);
          if (mintCount < cnt) {
            sessionStorage.removeItem("NFT_MINTED");
          }
        });
      }
      if (sessionStorage.getItem("ACCOUNT_CHANGED")) {
        setMintedCount(0);
        setNft([]);
        api.getAddressNfts(account).then((result) => {
          sessionStorage.removeItem("ACCOUNT_CHANGED");
          const cnt = result.data.data.length;
          sessionStorage.setItem("MINTED_COUNT", cnt);
          setNft(result.data.data);
          setMintedCount(cnt);
        });
        api.addWalletAddress(account);
      }
    }, 1000);
    nftMaxMints().then((maxMints) => {
      setMaxMint(String(maxMints));
      const { account } = getWalletSession();
      api.getAddressNfts(account).then((result) => {
        const cnt = result.data.data.length;
        sessionStorage.setItem("MINTED_COUNT", cnt);
        setNft(result.data.data);
        setMintedCount(cnt);
      });
    });
  }, []);

  return (
    <div className="nfts-section" style={{ marginTop: "70px" }}>
      <div className="nfts-info">
        <h2>My Nft's</h2>
        <p>
          you currently have {mintedCount}/{maxMint} NFTs minted.
        </p>
      </div>
      <div className="nfts-images">
        {Array.apply(null, new Array(mintedCount))
          .map(Number.prototype.valueOf, 0)
          .map((_, i) => i + 1)
          .map((v) => {
            return (
              <MintedNftItem
                index={v}
                heading={nft[v - 1].name}
                image={nft[v - 1].image}
              />
            );
          })}
        {Array.apply(null, new Array(maxMint - mintedCount))
          .map(Number.prototype.valueOf, 0)
          .map((_, i) => i + 1)
          .map((v) => (
            <BlankNftImage index={v + mintedCount} />
          ))}
      </div>
    </div>
  );
}

export { MintingSection };
